import {
  CourierSheduleResponse,
  CourierScheduleData,
  SchedulesByDate,
} from '@core/interfaces/courier-shedule.interface';
import { Courier } from '@core/interfaces/courier-directory.interface';
import { omit } from 'lodash-es';

export function getGroupedSchedules(
  data: Array<Courier & { workSchedules: CourierSheduleResponse[] }>,
): CourierScheduleData[] {
  const groupedSchedules = data.map((courier) => ({
    courier: omit(courier, ['workSchedules']),
    schedulesByDate: courier.workSchedules.reduce((acc: SchedulesByDate, schedule: CourierSheduleResponse) => {
      const { shiftDate } = schedule;

      if (!acc[shiftDate]) {
        acc[shiftDate] = [];
      }

      acc[shiftDate].push(schedule);

      return acc;
    }, {}),
  }));

  return Object.values(groupedSchedules).map((group: any) => ({
    courier: group.courier,
    schedulesByDate: Object.entries(group.schedulesByDate).map(([date, schedules]: any) => ({
      date,
      schedules,
    })),
  }));
}

export function getUniqueDates(couriersScheduleData: CourierScheduleData[]): string[] {
  const dates = new Set<string>();
  couriersScheduleData.forEach((courier: CourierScheduleData) => {
    courier.schedulesByDate.forEach((scheduleByDate: { date: string }) => {
      dates.add(scheduleByDate.date);
    });
  });
  return Array.from(dates).sort();
}

export function prepareScheduleMatrix(
  couriersScheduleData: CourierScheduleData[],
  arrayDate: string[],
): CourierSheduleResponse[][][] {
  return couriersScheduleData.map((courierData: CourierScheduleData) => arrayDate.map((date) => {
    const scheduleData = courierData.schedulesByDate.find(
      (schedule: { date: string; schedules: CourierSheduleResponse[] }) => schedule.date === date,
    );
    return scheduleData ? scheduleData.schedules : [];
  }));
}
